import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import { SEO } from "../components/SEO/SEO";

import { PortfolioProvider } from '../context/context';

import { headerData, footerData } from '../mock/data';

function ErrorPage() {
  const [header, setHeader] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, footer }}>
      <Layout>
      <SEO title={'Nick Jeske - 404 Not Found'}/>
      <div className="error-page">
        <div className="error-subheader">
        <h1 className="section-title">Page Not Found</h1>
        <h3>Ooops! The page you are looking for has been removed or relocated.</h3>
        <a href='/'>Go Back</a>
        </div>
      </div>
      </Layout>
    </PortfolioProvider>
  );
}
export default ErrorPage;

// export const Head = () => (
//   <SEO title={'Nick Jeske - 404 Not Found'}/>
// );